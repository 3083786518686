import React from 'react'
import styled from 'styled-components'
const DownsellColorWrapper = styled.div`
    margin-top: 1.6rem;
    .accepted {
        .Polaris-Card:first-child {
            background: #7EACA1;
            .customization, .product-edit{
                .Polaris-Card {
                    background: white;
                }
            }
        }
    }
    .denied {
        .Polaris-Card:first-child {
            background: #AEB4B9;
            .customization, .product-edit{
                .Polaris-Card {
                    background: white;
                }
            }
        }
    }
`
function DownsellColor(props) {
    return (
        <DownsellColorWrapper>
            {props.children}
        </DownsellColorWrapper>
            
    )
}
export default DownsellColor;